"use strict";
exports.__esModule = true;
exports.ServiceTypeV2 = exports.LabelClaimReason = exports.WeightUnits = exports.DimensionUnits = exports.ServiceType = exports.PackageWeight = exports.PackageSize = exports.ServiceAddOns = void 0;
var ServiceAddOns;
(function (ServiceAddOns) {
    ServiceAddOns["SIGNATURE"] = "SIGNATURE";
    ServiceAddOns["CHECK_ID"] = "CHECK_ID";
    ServiceAddOns["XL_PACKAGE"] = "XL_PACKAGE";
    ServiceAddOns["ADDRESS_CORRECTION"] = "ADDRESS_CORRECTION";
    ServiceAddOns["RELABELLING_FEE"] = "RELABELLING_FEE";
    ServiceAddOns["WAITING_FEE"] = "WAITING_FEE";
})(ServiceAddOns = exports.ServiceAddOns || (exports.ServiceAddOns = {}));
var PackageSize;
(function (PackageSize) {
    PackageSize["DEFAULT"] = "DEFAULT";
    PackageSize["SMALL"] = "SMALL";
    PackageSize["MEDIUM"] = "MEDIUM";
    PackageSize["LARGE"] = "LARGE";
    PackageSize["EXTRA_LARGE"] = "EXTRA_LARGE";
})(PackageSize = exports.PackageSize || (exports.PackageSize = {}));
var PackageWeight;
(function (PackageWeight) {
    PackageWeight["VERY_LIGHT"] = "VERY_LIGHT";
    PackageWeight["LIGHT"] = "LIGHT";
    PackageWeight["MEDIUM"] = "MEDIUM";
    PackageWeight["HEAVY"] = "HEAVY";
    PackageWeight["V1_HEAVY"] = "V1_HEAVY";
    PackageWeight["V2_HEAVY"] = "V2_HEAVY";
    PackageWeight["V3_HEAVY"] = "V3_HEAVY";
    PackageWeight["V4_HEAVY"] = "V4_HEAVY";
})(PackageWeight = exports.PackageWeight || (exports.PackageWeight = {}));
var ServiceType;
(function (ServiceType) {
    ServiceType["SAME_DAY"] = "SAMEDAY";
    ServiceType["NEXT_DAY"] = "NEXTDAY";
})(ServiceType = exports.ServiceType || (exports.ServiceType = {}));
var DimensionUnits;
(function (DimensionUnits) {
    DimensionUnits["INCH"] = "inch";
    DimensionUnits["FEET"] = "feet";
    DimensionUnits["METER"] = "meter";
})(DimensionUnits = exports.DimensionUnits || (exports.DimensionUnits = {}));
var WeightUnits;
(function (WeightUnits) {
    WeightUnits["POUND"] = "pound";
    WeightUnits["KILOGRAM"] = "kilogram";
})(WeightUnits = exports.WeightUnits || (exports.WeightUnits = {}));
var LabelClaimReason;
(function (LabelClaimReason) {
    LabelClaimReason["LOST"] = "LOST";
    LabelClaimReason["DAMAGED"] = "DAMAGED";
    LabelClaimReason["THEFT"] = "THEFT";
    LabelClaimReason["DELIVERED_TO_WRONG_ADDRESS"] = "DELIVERED_TO_WRONG_ADDRESS";
    LabelClaimReason["DELIVERED_OUTSIDE_OF_SLA"] = "DELIVERED_OUTSIDE_OF_SLA";
    LabelClaimReason["DRIVER_OR_CUSTOMER_INCIDENT"] = "DRIVER_OR_CUSTOMER_INCIDENT";
    LabelClaimReason["OTHER"] = "OTHER";
})(LabelClaimReason = exports.LabelClaimReason || (exports.LabelClaimReason = {}));
var ServiceTypeV2;
(function (ServiceTypeV2) {
    ServiceTypeV2["EXPRESS"] = "EXPRESS";
    ServiceTypeV2["PRIORITY"] = "PRIORITY";
    ServiceTypeV2["EXPEDITED"] = "EXPEDITED";
    ServiceTypeV2["GROUND"] = "GROUND";
})(ServiceTypeV2 = exports.ServiceTypeV2 || (exports.ServiceTypeV2 = {}));
