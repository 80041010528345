import {
  Typography,
  Box,
  Stack,
  styled,
  CircleIcon,
  CheckCircleIcon,
  ThemeType,
  SxProps,
  Button,
} from "@helo/ui";
import { useTranslate, useNotify } from "react-admin";
import { useMerchantOnboardingContext } from "~/components/MerchantOnboardingContext";

const Steps = ({ sx }: { sx?: SxProps<ThemeType> }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const {
    steps,
    currentStepIdx,
    markStepAsComplete,
    goToNextStep,
    currentStep,
  } = useMerchantOnboardingContext();

  const skipLabelCreate = async () => {
    try {
      await markStepAsComplete(currentStep.id);
      goToNextStep();
    } catch (err: any) {
      console.error(err);
      notify(`${err?.message ?? JSON.stringify(err)}`, {
        type: "warning",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <StepsContainer sx={sx}>
      {steps &&
        steps.map((step, idx) => (
          <Box key={`${step.title}-${idx}`}>
            <StepContainer
              className={`${idx === currentStepIdx ? "isCurrent" : ""} ${
                step.isComplete ? "isComplete" : ""
              }`}
            >
              {step.isComplete && idx !== currentStepIdx ? (
                <CheckCircleIcon
                  sx={({ palette }) => ({
                    color: palette.success.main,
                    mr: 1,
                  })}
                />
              ) : (
                <CircleIcon
                  sx={({ palette }) => ({
                    color:
                      idx === currentStepIdx
                        ? palette.primary.main
                        : palette.neutralScale[700],
                    mr: 1,
                  })}
                />
              )}
              <Typography variant="subtitle1" component="h3">
                {translate(step.label)}
              </Typography>
            </StepContainer>
            {currentStep.id === "shipments" && idx === currentStepIdx && (
              <SkipContainer>
                <Button
                  onClick={skipLabelCreate}
                  variant={"text"}
                  size={"small"}
                  sx={{ width: "fit-content", m: "0 0 16px 24px" }}
                >
                  {translate("skip")}
                </Button>
              </SkipContainer>
            )}
          </Box>
        ))}
    </StepsContainer>
  );
};

const StepContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContents: "center",
  alignItems: "center",
  padding: "8px",
  color: theme.palette.neutralScale[400],
  [theme.breakpoints.up("md")]: {
    padding: "16px",
  },
}));

const StepsContainer = styled(Stack)(({ theme }) => ({
  "& .isCurrent": {
    backgroundColor: theme.palette.primaryScale[1000],
    color: theme.palette.primary.dark,
  },
}));
const SkipContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primaryScale[1000],
  display: "flex",
}));

export default Steps;
